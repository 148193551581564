<template>
  <div class="about" id="dementie-information">
    <h1>Zorgstandaard Dementie (ZSD) - Netwerkanalyse</h1>
    <p v-if="index===1">
      Najaar 2020 is de Nationale Dementiestrategie 2021-2030 gepubliceerd. Onderdeel daarvan is voortzetting van het Register DementieZorg en -Ondersteuning (DZO).
      Onderstaande vragenlijst helpt de inplementatie van de Zorgstandaard te toetsen.
    </p>

    <div v-if="questions" class="form">

      <div v-if="index===1">
        <div class="mb-3 row"></div>

        <div class="mb-3 row"></div>
        <div class="select-network">
          <label class='col-sm-6' for="Netwerk">Onder welk dementienetwerk valt u?</label>
          <b-form-select v-model="respondent.dementienetwerk" :options="netwerken" size="lg" class="col-sm-6" required></b-form-select>
            <!-- <div>{{ returnSelection (selected) }}</div> -->
        </div>

        <div class="mb-3 row"></div>
        <div class="form-group">
          <label class='col-sm-6' for="Wachtwoord">Wat is het wachtwoord?</label>
          <input v-model="wachtwoord" type="password" class="col-sm-6" id="Wachtwoord" placeholder="Wachtwoord" required>
        </div>

        <div v-if="error" class="alert alert-danger" role="alert">
        Wachtwoord incorrect
        </div>

        <div class="mb-3 row"></div>
        <div class="form-group">
          <label class='col-sm-6' for="Organisatie">Bij welke organisatie bent u in dienst?</label>
          <input v-model="respondent.organisatie" type="Organisatie" class="col-sm-6" id="Organisatie" placeholder="Organisatie" required>
        </div>

        <div class="mb-3 row"></div>
        <div class="form-group">
          <label class='col-sm-6' for="Email">Wat is uw email?</label>
          <input type="Email" v-model="respondent.email" class="col-sm-6" id="Email" placeholder="Email" required>
        </div>

        <div class="mb-3 row"></div>
        <div class="select-network">
          <label class='col-sm-6' for="Functie">Wat is uw functie?</label>
          <b-form-select v-model="respondent.functie" :options="functies" size="lg" class="col-sm-6" required></b-form-select>
            <!-- <div>{{ returnSelection (selected) }}</div> -->
        </div>

        <div class="mb-3 row"></div>
        <div class="select-network">
          <label class='col-sm-6' for="Muraal">Werkt u voornamelijk intramuraal of extramuraal?</label>
          <b-form-select v-model="respondent.muraal" :options="muraal" size="lg" class="col-sm-6" required></b-form-select>
            <!-- <div>{{ returnSelection (selected) }}</div> -->
        </div>
        <button @click="volgende" class="btn btn-primary lager" :disabled="isDisabled1">volgende</button>
      </div>

      <div v-if="index===2">
        <div class="mb-3 row"></div>
        <p><b>Themas:</b> In het concept van de herziene Zorgstandaard Dementie zijn acht thema’s benoemd.
        Geef voor deze thema’s aan hoe belangrijk u het vindt dat hier aandacht voor is in uw dementienetwerk (1 = heel onbelangrijk, 5 = heel belangrijk).
        Geef daarnaast aan in welke mate de thema’s al zijn gerealiseerd in uw dementienetwerk (1 = helemaal niet, 5 = helemaal).
        </p>
        <div class="mb-3 row"></div>
        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[0].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[0].belangrijkheid" :value="item.value" :key="`1a-inner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault1" :id="`flexRadioDefault1-${idx}`" required>
                <label :key="`1a-inner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault1-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[0].realisatie_beschikbaar" :value="item.value" :key="`1b-inner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie1" :id="`realisatie1-${idx}`" required>
                <label :key="`1b-inner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie1${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[1].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[1].belangrijkheid" :value="item.value" :key="`2a-inner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault2" :id="`flexRadioDefault2-${idx}`" required>
                <label :key="`2a-inner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault2${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[1].realisatie_beschikbaar" :value="item.value" :key="`2b-inner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie2" :id="`realisatie2-${idx}`" required>
                <label :key="`2b-inner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie2-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[2].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[2].belangrijkheid" :value="item.value" :key="`3a-inner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault3" :id="`flexRadioDefault3-${idx}`" required>
                <label :key="`3a-inner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault3-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[2].realisatie_beschikbaar" :value="item.value" :key="`3b-inner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie3" :id="`realisatie3-${idx}`" required>
                <label :key="`3b-inner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie3-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[3].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[3].belangrijkheid" :value="item.value" :key="`4a-inner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault4" :id="`flexRadioDefault4-${idx}`" required>
                <label :key="`4a-inner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault4-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[3].realisatie_beschikbaar" :value="item.value" :key="`4b-inner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie4" :id="`realisatie4-${idx}`" required>
                <label :key="`4b-inner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie4-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[4].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[4].belangrijkheid" :value="item.value" :key="`5a-inner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault5" :id="`flexRadioDefault5-${idx}`" required>
                <label :key="`5a-inner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault5-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[4].realisatie_beschikbaar" :value="item.value" :key="`5b-inner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie5" :id="`realisatie5-${idx}`" required>
                <label :key="`5b-inner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie5-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[5].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[5].belangrijkheid" :value="item.value" :key="`6ainner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault6" :id="`flexRadioDefault6-${idx}`" required>
                <label :key="`6ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault6-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[5].realisatie_beschikbaar" :value="item.value" :key="`6binner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie6" :id="`realisatie6-${idx}`" required>
                <label :key="`6binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie6-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[6].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[6].belangrijkheid" :value="item.value" :key="`7ainner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault7" :id="`flexRadioDefault7-${idx}`" required>
                <label :key="`7ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault7-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[6].realisatie_beschikbaar" :value="item.value" :key="`7binner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie7" :id="`realisatie7-${idx}`" required>
                <label :key="`7binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie7-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[7].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[7].belangrijkheid" :value="item.value" :key="`8ainner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault8" :id="`flexRadioDefault8-${idx}`" required>
                <label :key="`8ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault8-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[7].realisatie_beschikbaar" :value="item.value" :key="`8binner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie8" :id="`realisatie8-${idx}`" required>
                <label :key="`8binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie8-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>
        <button @click="volgende" class="btn btn-primary lager" :disabled="isDisabled2">volgende</button>
        <button @click="vorige" class="btn btn-primary lager">vorige</button>
      </div>

      <div v-if="index===3">
        <div class="mb-3 row"></div>
        <p><b>Aanbevelingen uit de Zorgstandaard Dementie:</b> Het concept van de herziene Zorgstandaard Dementie bevat 25 aanbevelingen.
        Geef voor deze aanbevelingen aan hoe belangrijk u het vindt dat hier aandacht voor is in uw dementienetwerk (1 = heel onbelangrijk, 5 = heel belangrijk).
        Geef daarnaast aan in welke mate de aanbevelingen al zijn gerealiseerd in uw dementienetwerk (1 = helemaal niet, 5 = helemaal).
        </p>

        <div class="mb-3 row"></div>
        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[8].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[8].belangrijkheid" :value="item.value" :key="`9ainner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault9" :id="`flexRadioDefault9-${idx}`" required>
                <label :key="`9ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault9-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[8].realisatie_beschikbaar" :value="item.value" :key="`9binner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie9" :id="`realisatie9-${idx}`" required>
                <label :key="`9binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie9-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[9].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[9].belangrijkheid" :value="item.value" :key="`10ainner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault10" :id="`flexRadioDefault10-${idx}`" required>
                <label :key="`10ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault10-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[9].realisatie_beschikbaar" :value="item.value" :key="`10binner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie10" :id="`realisatie10-${idx}`" required>
                <label :key="`10binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie10-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[10].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[10].belangrijkheid" :value="item.value" :key="`11ainner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault11" :id="`flexRadioDefault11-${idx}`" required>
                <label :key="`11ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault11-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[10].realisatie_beschikbaar" :value="item.value" :key="`11binner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie11" :id="`realisatie11-${idx}`" required>
                <label :key="`11binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie11-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[11].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[11].belangrijkheid" :value="item.value" :key="`12ainner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault12" :id="`flexRadioDefault12-${idx}`" required>
                <label :key="`12ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault12-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[11].realisatie_beschikbaar" :value="item.value" :key="`12binner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie12" :id="`realisatie12-${idx}`" required>
                <label :key="`12binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie12-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[12].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[12].belangrijkheid" :value="item.value" :key="`13ainner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="flexRadioDefault13" :id="`flexRadioDefault13-${idx}`" required>
                <label :key="`13ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault13-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[12].realisatie_beschikbaar" :value="item.value" :key="`13binner-loop-input-${idx}`" class="form-check-input col-sm-1" type="radio" name="realisatie13" :id="`realisatie13-${idx}`" required>
                <label :key="`13binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie13-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[13].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[13].belangrijkheid" :value="item.value" :key="`14ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault14" :id="`flexRadioDefault14-${idx}`" required>
                <label :key="`14ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault14-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[13].realisatie_beschikbaar" :value="item.value" :key="`14binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie14" :id="`realisatie14-${idx}`" required>
                <label :key="`14binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie14-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[14].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[14].belangrijkheid" :value="item.value" :key="`15ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault15" :id="`flexRadioDefault15-${idx}`" required>
                <label :key="`15ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault15-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[14].realisatie_beschikbaar" :value="item.value" :key="`15binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie15" :id="`realisatie15-${idx}`" required>
                <label :key="`15binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie15-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[15].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[15].belangrijkheid" :value="item.value" :key="`16ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault16" :id="`flexRadioDefault16-${idx}`" required>
                <label :key="`16ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault16-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[15].realisatie_beschikbaar" :value="item.value" :key="`16binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie16" :id="`realisatie16-${idx}`" required>
                <label :key="`16binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie16-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[16].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[16].belangrijkheid" :value="item.value" :key="`17ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault17" :id="`flexRadioDefault17-${idx}`" required>
                <label :key="`17ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault17-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[16].realisatie_beschikbaar" :value="item.value" :key="`17binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie17" :id="`realisatie17-${idx}`" required>
                <label :key="`17binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie17-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[17].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[17].belangrijkheid" :value="item.value" :key="`18ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault18" :id="`flexRadioDefault18-${idx}`" required>
                <label :key="`18ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault18-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[17].realisatie_beschikbaar" :value="item.value" :key="`18binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie18" :id="`realisatie18-${idx}`" required>
                <label :key="`18binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie18-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[18].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[18].belangrijkheid" :value="item.value" :key="`19ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault19" :id="`flexRadioDefault19-${idx}`" required>
                <label :key="`19ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault19-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[18].realisatie_beschikbaar" :value="item.value" :key="`19binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie19" :id="`realisatie19-${idx}`" required>
                <label :key="`19binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie19-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[19].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[19].belangrijkheid" :value="item.value" :key="`20ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault20" :id="`flexRadioDefault20-${idx}`" required>
                <label :key="`20ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault20-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[19].realisatie_beschikbaar" :value="item.value" :key="`20binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie20" :id="`realisatie20-${idx}`" required>
                <label :key="`20binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie20-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[20].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[20].belangrijkheid" :value="item.value" :key="`21ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault21" :id="`flexRadioDefault21-${idx}`" required>
                <label :key="`21ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault21-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[20].realisatie_beschikbaar" :value="item.value" :key="`21binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie21" :id="`realisatie21-${idx}`" required>
                <label :key="`21binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie21-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>
        <button @click="volgende" class="btn btn-primary lager" :disabled="isDisabled3">volgende</button>
        <button @click="vorige" class="btn btn-primary lager">vorige</button>
      </div>

      <div v-if="index===4">
        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[21].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[21].belangrijkheid" :value="item.value" :key="`22ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault22" :id="`flexRadioDefault22-${idx}`" required>
                <label :key="`22ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault22-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[21].realisatie_beschikbaar" :value="item.value" :key="`22binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie22" :id="`realisatie22-${idx}`" required>
                <label :key="`22binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie22-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[22].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[22].belangrijkheid" :value="item.value" :key="`23ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault23" :id="`flexRadioDefault23-${idx}`" required>
                <label :key="`23ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault23-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[22].realisatie_beschikbaar" :value="item.value" :key="`23binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie23" :id="`realisatie23-${idx}`" required>
                <label :key="`23binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie23-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[23].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[23].belangrijkheid" :value="item.value" :key="`24ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault24" :id="`flexRadioDefault24-${idx}`" required>
                <label :key="`24ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault24-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[23].realisatie_beschikbaar" :value="item.value" :key="`24binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie24" :id="`realisatie24-${idx}`" required>
                <label :key="`24binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie24-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[24].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[24].belangrijkheid" :value="item.value" :key="`25ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault25" :id="`flexRadioDefault25-${idx}`" required>
                <label :key="`25ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault25-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[24].realisatie_beschikbaar" :value="item.value" :key="`25binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie25" :id="`realisatie25-${idx}`" required>
                <label :key="`25binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie25-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[25].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[25].belangrijkheid" :value="item.value" :key="`26ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault26" :id="`flexRadioDefault26-${idx}`" required>
                <label :key="`26ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault26-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[25].realisatie_beschikbaar" :value="item.value" :key="`26binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie26" :id="`realisatie26-${idx}`" required>
                <label :key="`26binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie26-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[26].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[26].belangrijkheid" :value="item.value" :key="`27ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault27" :id="`flexRadioDefault27-${idx}`" required>
                <label :key="`27ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault27-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[26].realisatie_beschikbaar" :value="item.value" :key="`27binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie27" :id="`realisatie27-${idx}`" required>
                <label :key="`27binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie27-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[27].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[27].belangrijkheid" :value="item.value" :key="`28ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault28" :id="`flexRadioDefault28-${idx}`" required>
                <label :key="`28ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault28-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[27].realisatie_beschikbaar" :value="item.value" :key="`28binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie28" :id="`realisatie28-${idx}`" required>
                <label :key="`28binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie28-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[28].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[28].belangrijkheid" :value="item.value" :key="`29ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault29" :id="`flexRadioDefault29-${idx}`" required>
                <label :key="`29ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault29-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[28].realisatie_beschikbaar" :value="item.value" :key="`29binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie29" :id="`realisatie29-${idx}`" required>
                <label :key="`29binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie29-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[29].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[29].belangrijkheid" :value="item.value" :key="`30ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault30" :id="`flexRadioDefault30-${idx}`" required>
                <label :key="`30ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault30-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[29].realisatie_beschikbaar" :value="item.value" :key="`30binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie30" :id="`realisatie30-${idx}`" required>
                <label :key="`30binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie30-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[30].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[30].belangrijkheid" :value="item.value" :key="`31ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault31" :id="`flexRadioDefault31-${idx}`" required>
                <label :key="`31ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault31-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[30].realisatie_beschikbaar" :value="item.value" :key="`31binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie31" :id="`realisatie31-${idx}`" required>
                <label :key="`31binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie31-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[31].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[31].belangrijkheid" :value="item.value" :key="`32ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault32" :id="`flexRadioDefault32-${idx}`" required>
                <label :key="`32ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault32-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[31].realisatie_beschikbaar" :value="item.value" :key="`32binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie32" :id="`realisatie32-${idx}`" required>
                <label :key="`32binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie32-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[32].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[32].belangrijkheid" :value="item.value" :key="`33ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault33" :id="`flexRadioDefault33-${idx}`" required>
                <label :key="`33ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault33-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">helemaal niet gerealiseerd</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in realisaties">
                <input v-model="questions[32].realisatie_beschikbaar" :value="item.value" :key="`33binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie33" :id="`realisatie33-${idx}`" required>
                <label :key="`33binner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`realisatie33-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">helemaal gerealiseerd</div>
        </div>
        <button @click="volgende" class="btn btn-primary lager" :disabled="isDisabled4">volgende</button>
        <button @click="vorige" class="btn btn-primary lager">vorige</button>
      </div>

      <div v-if="index===5">
        <div class="mb-3 row"></div>
        <p><b>Kwaliteitsindicatoren</b>
        Hieronder staan de kwaliteitsindicatoren uit de Zorgstandaard Dementie.
        Geef voor deze indicatoren aan hoe belangrijk u deze vindt om dementiezorg en -ondersteuning te monitoren, en op basis hiervan te leren en te verbeteren (1 = heel onbelangrijk, 5 = heel belangrijk).
        Geef daarnaast aan of deze gegevens beschikbaar zijn voor uw dementienetwerk.

        Kwaliteitsindicatoren:
        P1-3: Procesindicatoren
        S1-3: Structuurindicatoren
        U1-6: Uitkomstindicatoren
        </p>
        <div class="mb-3 row"></div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[33].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[33].belangrijkheid" :value="item.value" :key="`34ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault34" :id="`flexRadioDefault34-${idx}`" required>
                <label :key="`34ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault34-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">Beschikbaar:</div>
          <div class="col-3">
              <template v-for="(item, idx) in beschikbaar">
                <input v-model="questions[33].realisatie_beschikbaar" :value="item.value" :key="`34binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie34" :id="`beschikbaar34-${idx}`" required>
                <label :key="`34binner-loop-label-${idx}`" class="form-check-label col-md-3" :for="`beschikbaar34-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[34].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[34].belangrijkheid" :value="item.value" :key="`35ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault35" :id="`flexRadioDefault35-${idx}`" required>
                <label :key="`35ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault35-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">Beschikbaar:</div>
          <div class="col-3">
              <template v-for="(item, idx) in beschikbaar">
                <input v-model="questions[34].realisatie_beschikbaar" :value="item.value" :key="`35binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie35" :id="`beschikbaar35-${idx}`" required>
                <label :key="`35binner-loop-label-${idx}`" class="form-check-label col-md-3" :for="`beschikbaar35-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[35].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[35].belangrijkheid" :value="item.value" :key="`36ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault36" :id="`flexRadioDefault36-${idx}`" required>
                <label :key="`36ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault36-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">Beschikbaar:</div>
          <div class="col-3">
              <template v-for="(item, idx) in beschikbaar">
                <input v-model="questions[35].realisatie_beschikbaar" :value="item.value" :key="`36binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie36" :id="`beschikbaar36-${idx}`" required>
                <label :key="`36binner-loop-label-${idx}`" class="form-check-label col-md-3" :for="`beschikbaar36-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[36].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[36].belangrijkheid" :value="item.value" :key="`37ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault37" :id="`flexRadioDefault37-${idx}`" required>
                <label :key="`37ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault37-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">Beschikbaar:</div>
          <div class="col-3">
              <template v-for="(item, idx) in beschikbaar">
                <input v-model="questions[36].realisatie_beschikbaar" :value="item.value" :key="`37binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie37" :id="`beschikbaar37-${idx}`" required>
                <label :key="`37binner-loop-label-${idx}`" class="form-check-label col-md-3" :for="`beschikbaar37-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[37].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[37].belangrijkheid" :value="item.value" :key="`38ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault38" :id="`flexRadioDefault38-${idx}`" required>
                <label :key="`38ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault38-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">Beschikbaar:</div>
          <div class="col-3">
              <template v-for="(item, idx) in beschikbaar">
                <input v-model="questions[37].realisatie_beschikbaar" :value="item.value" :key="`38binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie38" :id="`beschikbaar38-${idx}`" required>
                <label :key="`38binner-loop-label-${idx}`" class="form-check-label col-md-3" :for="`beschikbaar38-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[38].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[38].belangrijkheid" :value="item.value" :key="`39ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault39" :id="`flexRadioDefault39-${idx}`" required>
                <label :key="`39ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault39-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">Beschikbaar:</div>
          <div class="col-3">
              <template v-for="(item, idx) in beschikbaar">
                <input v-model="questions[38].realisatie_beschikbaar" :value="item.value" :key="`39binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie39" :id="`beschikbaar39-${idx}`" required>
                <label :key="`39binner-loop-label-${idx}`" class="form-check-label col-md-3" :for="`beschikbaar39-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[39].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[39].belangrijkheid" :value="item.value" :key="`40ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault40" :id="`flexRadioDefault40-${idx}`" required>
                <label :key="`40ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault40-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">Beschikbaar:</div>
          <div class="col-3">
              <template v-for="(item, idx) in beschikbaar">
                <input v-model="questions[39].realisatie_beschikbaar" :value="item.value" :key="`40binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie40" :id="`beschikbaar40-${idx}`" required>
                <label :key="`40binner-loop-label-${idx}`" class="form-check-label col-md-3" :for="`beschikbaar40-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[40].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[40].belangrijkheid" :value="item.value" :key="`41ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault41" :id="`flexRadioDefault41-${idx}`" required>
                <label :key="`41ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault41-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">Beschikbaar:</div>
          <div class="col-3">
              <template v-for="(item, idx) in beschikbaar">
                <input v-model="questions[40].realisatie_beschikbaar" :value="item.value" :key="`41binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie41" :id="`beschikbaar41-${idx}`" required>
                <label :key="`41binner-loop-label-${idx}`" class="form-check-label col-md-3" :for="`beschikbaar41-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[41].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[41].belangrijkheid" :value="item.value" :key="`42ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault42" :id="`flexRadioDefault42-${idx}`" required>
                <label :key="`42ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault42-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">Beschikbaar:</div>
          <div class="col-3">
              <template v-for="(item, idx) in beschikbaar">
                <input v-model="questions[41].realisatie_beschikbaar" :value="item.value" :key="`42binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie42" :id="`beschikbaar42-${idx}`" required>
                <label :key="`42binner-loop-label-${idx}`" class="form-check-label col-md-3" :for="`beschikbaar42-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[42].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[42].belangrijkheid" :value="item.value" :key="`43ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault43" :id="`flexRadioDefault43-${idx}`" required>
                <label :key="`43ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault43-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">Beschikbaar:</div>
          <div class="col-3">
              <template v-for="(item, idx) in beschikbaar">
                <input v-model="questions[42].realisatie_beschikbaar" :value="item.value" :key="`43binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie43" :id="`beschikbaar43-${idx}`" required>
                <label :key="`43binner-loop-label-${idx}`" class="form-check-label col-md-3" :for="`beschikbaar43-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[43].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[43].belangrijkheid" :value="item.value" :key="`44ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault44" :id="`flexRadioDefault44-${idx}`" required>
                <label :key="`44ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault44-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">Beschikbaar:</div>
          <div class="col-3">
              <template v-for="(item, idx) in beschikbaar">
                <input v-model="questions[43].realisatie_beschikbaar" :value="item.value" :key="`44binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie44" :id="`beschikbaar44-${idx}`" required>
                <label :key="`44binner-loop-label-${idx}`" class="form-check-label col-md-3" :for="`beschikbaar44-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-4">
            {{ questions[44].vraag }}
          </div>
          <div class="col-sm-1">heel onbelangrijk</div>
          <div class="col-sm-2">
              <template v-for="(item, idx) in options">
                <input v-model="questions[44].belangrijkheid" :value="item.value" :key="`45ainner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="flexRadioDefault45" :id="`flexRadioDefault45-${idx}`" required>
                <label :key="`45ainner-loop-label-${idx}`" class="form-check-label col-sm-1" :for="`flexRadioDefault45-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
          <div class="col-sm-1">heel belangrijk</div>
          <div class="col-sm-1">Beschikbaar:</div>
          <div class="col-3">
              <template v-for="(item, idx) in beschikbaar">
                <input v-model="questions[44].realisatie_beschikbaar" :value="item.value" :key="`45binner-loop-input-${idx}`" class="form-check-input col-sm-1"
                type="radio" name="realisatie45" :id="`beschikbaar45-${idx}`" required>
                <label :key="`45binner-loop-label-${idx}`" class="form-check-label col-md-3" :for="`beschikbaar45-${idx}`">
                  {{ item.text }}
                </label>
              </template>
          </div>
        </div>

      <button type="submit" @click="onSubmit" class="btn btn-primary lager" :disabled="isDisabled">Verstuur</button>
      <button @click="vorige" class="btn btn-primary lager">vorige</button>
    </div>
  </div>
  <div v-if="saved" class="alert alert-success" role="alert">
  Antwoorden opgeslagen, bedankt!
  </div>



  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        respondent: {
          dementienetwerk: '',
          organisatie: '',
          muraal: '',
          email: '',
          functie: '',
          antwoorden: [],
        },
        wachtwoord: '',
        functies: ['Directie','Management','Professional','Clientvertegenwoordiger','Anders'],
        muraal: ['Voornamelijk intramuraal', 'Voornamelijk extramuraal', 'Ongeveer evenveel intramuraal als extramuraal'],
        options: [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 }
        ],
        realisaties: [
            { text: '1', value: 6 },
            { text: '2', value: 7 },
            { text: '3', value: 8 },
            { text: '4', value: 9 },
            { text: '5', value: 10 }
        ],
        beschikbaar: [
          { text: 'ja', value: 11 },
          { text: 'nee', value: 12 },
          { text: 'weet ik niet', value: 13 }
        ],
        questions: null,
        netwerken: [],
        saved: false,
        error: false,
        index: 1,
      }
    },
    computed: {
      isDisabled4() {
        return (this.questions.map(d => d.realisatie_beschikbaar).slice(21,33).indexOf(undefined) != -1 ||
              this.questions.map(d => d.belangrijkheid).slice(21,33).indexOf(undefined) != -1) == true
      },
      isDisabled3() {
        return (this.questions.map(d => d.realisatie_beschikbaar).slice(8,21).indexOf(undefined) != -1 ||
              this.questions.map(d => d.belangrijkheid).slice(8,21).indexOf(undefined) != -1) == true
      },
      isDisabled2() {
        return (this.questions.map(d => d.realisatie_beschikbaar).slice(0,8).indexOf(undefined) != -1 ||
              this.questions.map(d => d.belangrijkheid).slice(0,8).indexOf(undefined) != -1) == true
      },
      isDisabled1() {
        return (this.wachtwoord == '' ||
              this.respondent.dementienetwerk == '' || this.respondent.organisatie == '' ||
              this.respondent.muraal == '' || this.respondent.functie == '' || this.respondent.email == '') == true
      },
      isDisabled() {
        return (this.questions.map(d => d.realisatie_beschikbaar).indexOf(undefined) != -1 ||
              this.questions.map(d => d.belangrijkheid).indexOf(undefined) != -1 || this.wachtwoord == '' ||
              this.respondent.dementienetwerk == '' || this.respondent.organisatie == '' ||
              this.respondent.muraal == '' || this.respondent.functie == '' || this.respondent.email == '') == true
      }
    },
    mounted () {
      this.$http.get('/api/dementienetwerk_totaal/').then(
      (response) => {
        this.netwerken = [...new Set(response.data.map(d => d.dementienetwerknaam))]
        this.netwerken.sort()
        }
      ),
      this.$http.get('/api/vragen/').then(
      (response) => {
        response.data.sort((a,b) => a.id-b.id)
        this.questions = response.data
        }
      )
    },
    methods: {
      ...mapActions(['checkLogin']),
      login() {
        // this.$v.$touch();
        // if (!this.$v.$invalid) {
            let user = {username: this.respondent.dementienetwerk, password: this.wachtwoord}
            axios.post(`${process.env.VUE_APP_API_URL}/rest-auth/login/`, user).then(
              (response) => {
                localStorage.setItem("TOKEN_STORAGE_KEY", response.data.key);
                Object.assign(this.$http.defaults, {
                  headers: { authorization: `Token ${response.data.key}` },
                });
                this.checkLogin()
                this.error = false
                this.index ++
              },
              () => {
                this.error = true
              }
          )
      },
      onSubmit() {
        this.respondent.antwoorden=this.questions
        this.$http.post('/api/respondenten/', this.respondent)
        this.saved = true
        this.questions = null
      },
      volgende() {
        if (this.index === 1) {
          this.login()
        }
        else if (this.index<5) {
          this.index = this.index + 1
        }
      },
      vorige() {
        if (this.index>1) {
          this.index = this.index - 1
        }
      }  // alert(JSON.stringify(this.form))
    }
  }
</script>

<style scoped>
.col-sm-1 {
text-align: center;
}
.col-sm-6 {
text-align: left;
}
.mb-3{
text-align: left;
}
.col-sm-3 {
  text-align: center;
}
.col-md-3 {
  margin-left: 5px;
}
.col-sm-2 {
  text-align: center;
}
.lager {
  margin-top: 30px;
}

.btn {
  float: right;
  background-color: var(--color-1);
  border-color: var(--color-2);
  margin-bottom: 20px;
}
</style>
